.App {
  text-align: center;
  font-family: sans-serif;
}

/* mobile */
@media (max-width: 600px) {
  body {
    /* background-color: #8acce6 !important; */
  }
  .NavRow {
    display: flex;
    flex-direction: column;
    /* style= {{display:'flex', flexWrap:'wrap', whiteSpace:'nowrap',  fontFamily:'monospace' }} */
  }

  .websiteName {
    display: none;
  }
}

@media only screen and (min-width: 601px) {
  /* body {
    background-image: url("./images/sky-bg.jpg");
  } */
  .NavRow {
    display: flex;
    flex-direction: row;
    /* style= {{display:'flex', flexWrap:'wrap', whiteSpace:'nowrap',  fontFamily:'monospace' }} */
  }
}

input,
textarea,
.Dropdown-root {
  border: 1px solid black;
  width: 200px;
}
button {
  min-width: 150px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container {
  font-family: sans-serif;
  width: auto;
}
.contact-us-container {
  width: auto;
}
.main {
  max-width: 2000px;
  text-align: center;
  margin: auto;
}
.main-container {
  height: 100%;
  text-align: center;
  font-family: "Lucida Console", Monaco, monospace;
  /* border: 1px solid white;
  border-radius: 10px; */
}
.header {
  color: red;
  text-align: center;
  margin: 20px;
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
}
.nav-bar {
  text-align: center;
  padding: 10px;
  height: 50%;
  background-color: white;
  font-family: sans-serif;
  font-size: medium;
  display: flex;
  justify-content: center;
}

@media (min-width: 1024px) {
  #detail_left_box {
    text-align: center;
    background-image: red;
    width: 30%;
  }
  #detail_right_box {
    width: 70%;
  }
}
/* mobile */
@media (max-width: 1023px) {
  #detail_left_box {
    text-align: center;
    width: 100%;
  }
  #detail_right_box {
    width: 100%;
  }

  .image-gallery-thumbnails-wrapper {
    display: none;
  }
}

@media (min-width: 680px) {
  .nav-bar {
    flex-direction: row;
  }
}
@media (max-width: 679px) {
  .nav-bar {
    flex-direction: column;
  }
  .logo {
    display: none;
  }
}

.nav-link {
  color: blue !important;
  margin-right: 2.5rem;
}

/* .navbar a:hover {
  border-bottom: 1px solid #85cae7 !important;
} */

.active {
  border-bottom: 1px solid #85cae7 !important;
  padding-bottom: 0.5em;
  color: white !important;
}
.navSpan {
  padding: 10px;
  color: white;
  font-size: 20px !important;
  font-family: Verdana;
}
.navSpan a {
  color: white !important;
  padding-left: 15px;
  padding-right: 15px;
  letter-spacing: 2px;
  text-decoration: none;
}
.navSpan a:hover {
  border-bottom: 1px solid #85cae7 !important;
  padding-bottom: 0.5em;
  color: white;
}

a {
  text-decoration: none;
  color: black !important;
}
.orderNowSpan {
  color: red !important;
  font-weight: bold;
}
.orderNowSpan a:hover {
  border-bottom: 1px solid #85cae7 !important;
}

.mainbird {
  width: 70%;
  margin-left: 20px;
  margin-top: 20px;
  text-align: left;
  line-height: 2;
}
.mainbird-text {
  border: 1px solid red;
}
.mainbird-image {
  border: 1px solid red;
  margin-left: 10px;
}
img {
  float: right;
}
.mission-statement {
  text-align: center !important;
  margin: 50px;
}

.flex-outer,
.flex-inner {
  list-style-type: none;
  padding: 0;
}

.flex-outer {
  max-width: 800px;
}

.flex-outer li,
.flex-inner {
  display: flex;
  flex-wrap: wrap;
  align-items: left;
}

.flex-inner {
  padding: 0 8px;
  justify-content: space-between;
}

.flex-outer > li:not(:last-child) {
  margin-bottom: 5px;
}

.flex-outer li label {
  text-align: left;
}

.flex-outer li p {
  padding: 8px;
  font-weight: 300;
  letter-spacing: 0.09em;
  text-transform: uppercase;
}

.flex-outer > li > label,
.flex-outer li p {
  flex: 0 0 120px;
  max-width: 220px;
}

.flex-outer > li > label + *,
.flex-inner {
  flex: 0 0 300px;
}

.react-datepicker-wrapper {
  text-align: left;
}
.flex-outer li p {
  margin: 0;
}

.flex-outer li input:not([type="checkbox"]) {
  padding: 5px;
  border: 1px solid grey;
}
.flex-outer li textarea {
  padding: 5px;
  border: 1px solid grey;
}

.flex-outer li button {
  margin-left: auto;
  padding: 8px 16px;
  border: none;
  background: #333;
  color: #f2f2f2;
  text-transform: uppercase;
  letter-spacing: 0.09em;
  border-radius: 2px;
}

.flex-inner li {
  width: 100px;
}

/* blog */
/* .product-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #ebe8e8;

  width: 500px;
  border-left: 0.5px solid grey;
  border-right: 0.5px solid grey;

  border-radius: 10px;
  margin: 5px;
  padding-left: 5px;
} */
/* catalog */
.catalog-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 600px;
  border-bottom: 2px solid red;
  border-radius: 10px;
  margin: 5px;
  padding-left: 5px;
}
/* 
display: "flex",
justifyContent: "center",
padding: "25px",
flexWrap: "wrap",
fontFamily: "sans-serif",
fontSize: "16px", */

.catalog-wrapper {
  display: flex;
  justify-content: center;
  padding: 25px;
  flex-wrap: wrap;
  font-family: sans-serif;
  font-size: 16px;
}

.catalog-link {
  font-weight: bolder;
  color: red;
  text-decoration: underline;
  cursor: pointer;
}
.col-item {
  padding: 5px;
}
/* .description {
  flex-grow: 3;
  display: flex;
  text-align: left;
  width: 100px;
  padding: 10px;
  font-size: 15px;
} */
.description {
  /* flex-grow: 3; */
  display: flex;
  text-align: left;
  min-width: 100px;
  line-height: 1.5;
  letter-spacing: 1px;
  font-size: 16px;
  padding: 10px;
  color: #e1d798;
}
.description-expanded {
  flex-grow: 3;
  display: flex;
  text-align: left;
  line-height: 1.5;
  letter-spacing: 1px;
  font-size: 16px;
  padding: 10px;
}
.col-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  margin: 5px;
}

.login {
  width: 300px;
}
.login-div {
  text-align: right;
  font-weight: bolder;
  color: red;
  margin: 10px;
}

.admin-container {
  padding: 10px;
  text-align: left;
  width: 100%;
  display: flex;
  justify-content: center;
}

.api-version {
  text-align: right;
  color: darkslategrey;
  font-size: 8px;
}

.counter-div {
  margin-top: 25px;
  text-align: center;
  vertical-align: middle;
  width: 10%;
  height: 25%;
}

.feature-card {
  background-color: #333;
  border: 1px solid black;
  border-radius: 10px;
  padding: 10px;
  color: white;
  margin: 10px;
}

._table {
  /* border: 1px solid black; */
  display: flex;
  flex-direction: column;
}
._row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

/* imagegalleroverride */

.image-gallery-slide-wrapper {
  width: 300px !important;
  height: 300px !important;
  object-fit: cover !important;
}
.image-gallery-swipe {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
}
.image-gallery-slides {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
}
.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 75px !important;
  width: 60px !important;
  top: 70% !important;
}
.image-gallery-left-nav,
.image-gallery-right-nav {
  min-width: 100px !important;
}
/* 
.image-gallery-slides{
  width: 100% !important;
  height: 100% !important;
} */
.image-gallery-content.bottom.fullscreen > .image-gallery-slide-wrapper {
  width: auto !important;
  height: auto !important;
}
.image-gallery-thumbnail-image {
  width: 100px !important;
  height: 100px !important;
  object-fit: cover !important;
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:hover,
.image-gallery-thumbnail:focus {
  border: none !important;
}
.image-gallery-thumbnails button {
  min-width: auto !important;
  margin: 5px;
}
/* ._column {
  display: flex;
  flex-direction: row;
} */
.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
}

.dev-border-blue {
  border: 5px solid blue;
}
.dev-border-red {
  border: 5px solid red;
}
.dev-border-yellow {
  border: 5px solid yellow;
}

.lightgray-border {
  border: 1px solid gray;
  padding: 10px;
  text-transform: capitalize;
}

.break {
  flex-basis: 100%;
  height: 0;
}
